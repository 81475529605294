:root {
  --btn-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
  --card-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
  --foundationbluesecondarytext-100: rgba(189, 189, 189, 1);
  --foundationbluesecondarytext-200: rgba(157, 157, 157, 1);
  --foundationbluesecondarytext-300: rgba(112, 112, 112, 1);
  --foundationbluesecondarytext-400: rgba(84, 84, 85, 1);
  --foundationbluesecondarytext-50: rgba(234, 234, 234, 1);
  --foundationbluesecondarytext-500: rgba(41, 41, 42, 1);
  --foundationbluesecondarytext-600: rgba(37, 37, 38, 1);
  --foundationbluesecondarytext-700: rgba(29, 29, 30, 1);
  --foundationbluesecondarytext-800: rgba(23, 23, 23, 1);
  --foundationbluesecondarytext-900: rgba(17, 17, 18, 1);
  --foundationyellowprimary-100: rgba(251, 233, 178, 1);
  --foundationyellowprimary-200: rgba(249, 222, 141, 1);
  --foundationyellowprimary-300: rgba(246, 207, 89, 1);
  --foundationyellowprimary-400: rgba(244, 198, 57, 1);
  --foundationyellowprimary-50: rgba(254, 248, 230, 1);
  --foundationyellowprimary-500: rgba(241, 184, 7, 1);
  --foundationyellowprimary-600: rgba(219, 167, 6, 1);
  --foundationyellowprimary-700: rgba(171, 131, 5, 1);
  --foundationyellowprimary-800: rgba(133, 101, 4, 1);
  --foundationyellowprimary-900: rgba(101, 77, 3, 1);

  --lemon: rgba(207, 229, 70, 1);
  --indigo: rgba(113, 39, 235, 1);
  --green: rgba(9, 198, 62, 1);
  --orange: rgba(255, 174, 17, 1);
  --red: rgba(236, 33, 6, 1);
  --blue: rgba(0, 212, 255, 1);
  --foundation-yellowy50: rgba(250, 252, 236, 1);
  --foundation-yellowy75: rgba(235, 244, 179, 1);
  --foundation-yellowy100: rgba(227, 240, 147, 1);
  --foundation-yellowy200: rgba(215, 233, 101, 1);
  --foundation-yellowy300: rgba(207, 229, 69, 1);
  --foundation-yellowy400: rgba(145, 160, 48, 1);
  --foundation-yellowy500: rgba(126, 140, 42, 1);
  --foundationyellowprimary-50: rgba(254, 248, 230, 1);
  --foundationyellowprimary-100: rgba(251, 233, 178, 1);
  --foundationyellowprimary-200: rgba(249, 222, 141, 1);
  --foundationyellowprimary-300: rgba(246, 207, 89, 1);
  --foundationyellowprimary-400: rgba(244, 198, 57, 1);
  --foundationyellowprimary-500: rgba(241, 184, 7, 1);
  --foundationyellowprimary-600: rgba(219, 167, 6, 1);
  --foundationyellowprimary-700: rgba(171, 131, 5, 1);
  --foundationyellowprimary-800: rgba(133, 101, 4, 1);
  --foundationyellowprimary-900: rgba(101, 77, 3, 1);
  --foundation-blueb50: rgba(230, 251, 255, 1);
  --foundation-blueb75: rgba(151, 237, 255, 1);
  --foundation-blueb100: rgba(108, 230, 255, 1);
  --foundation-blueb200: rgba(44, 219, 255, 1);
  --foundation-blueb300: rgba(1, 212, 255, 1);
  --foundation-blueb400: rgba(1, 148, 179, 1);
  --foundation-blueb500: rgba(1, 129, 156, 1);
  --foundationbluesecondarytext-50: rgba(234, 234, 234, 1);
  --foundationbluesecondarytext-100: rgba(189, 189, 189, 1);
  --foundationbluesecondarytext-200: rgba(157, 157, 157, 1);
  --foundationbluesecondarytext-300: rgba(112, 112, 112, 1);
  --foundationbluesecondarytext-400: rgba(84, 84, 85, 1);
  --foundationbluesecondarytext-500: rgba(41, 41, 42, 1);
  --foundationbluesecondarytext-600: rgba(37, 37, 38, 1);
  --foundationbluesecondarytext-700: rgba(29, 29, 30, 1);
  --foundationbluesecondarytext-800: rgba(23, 23, 23, 1);
  --foundationbluesecondarytext-900: rgba(17, 17, 18, 1);
  --foundation-violetv50: rgba(241, 233, 253, 1);
  --foundation-violetv75: rgba(197, 166, 247, 1);
  --foundation-violetv100: rgba(173, 130, 243, 1);
  --foundation-violetv200: rgba(137, 76, 238, 1);
  --foundation-violetv300: rgba(113, 39, 235, 1);
  --foundation-violetv400: rgba(79, 27, 165, 1);
  --foundation-violetv500: rgba(69, 24, 143, 1);
  --foundation-greeng50: rgba(230, 249, 236, 1);
  --foundation-greeng75: rgba(153, 232, 176, 1);
  --foundation-greeng100: rgba(111, 222, 143, 1);
  --foundation-greeng200: rgba(49, 208, 95, 1);
  --foundation-greeng300: rgba(7, 198, 62, 1);
  --foundation-greeng400: rgba(5, 139, 43, 1);
  --foundation-greeng500: rgba(4, 121, 38, 1);
  --foundation-orangeo50: rgba(255, 247, 231, 1);
  --foundation-orangeo75: rgba(255, 222, 157, 1);
  --foundation-orangeo100: rgba(255, 208, 117, 1);
  --foundation-orangeo200: rgba(255, 188, 57, 1);
  --foundation-orangeo300: rgba(255, 174, 17, 1);
  --foundation-orangeo400: rgba(179, 122, 12, 1);
  --foundation-orangeo500: rgba(156, 106, 10, 1);
  --foundation-redr50: rgba(253, 233, 230, 1);
  --foundation-redr75: rgba(247, 164, 153, 1);
  --foundation-redr100: rgba(244, 127, 110, 1);
  --foundation-redr200: rgba(239, 72, 48, 1);
  --foundation-redr300: rgba(236, 34, 5, 1);
  --foundation-redr400: rgba(165, 24, 4, 1);
  --foundation-redr500: rgba(144, 21, 3, 1);

}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.loading-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

:where(.css-dev-only-do-not-override-1vr7spz).ant-menu-light .ant-menu-item-selected a, :where(.css-dev-only-do-not-override-1vr7spz).ant-menu-light>.ant-menu .ant-menu-item-selected a, :where(.css-dev-only-do-not-override-1vr7spz).ant-menu-light .ant-menu-item-selected a:hover, :where(.css-dev-only-do-not-override-1vr7spz).ant-menu-light>.ant-menu .ant-menu-item-selected a:hover {
    color: #856504;
}

:where(.css-dev-only-do-not-override-1vr7spz).ant-table-wrapper {
    clear: both;
    max-width: 100%;
    width: 88%;
}