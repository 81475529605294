.sidebarMain {
  background: $white;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.375rem 0.625rem 0 0.625rem;
  height: calc(100vh - 93px);
  overflow-y: auto;
  
  :where(.css-dev-only-do-not-override-yp8pcc).ant-menu-light
    .ant-menu-submenu-selected
    > .ant-menu-submenu-title,
  :where(.css-dev-only-do-not-override-yp8pcc).ant-menu-light
    > .ant-menu
    .ant-menu-submenu-selected
    > .ant-menu-submenu-title {
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.313rem;
    color: $gray-600 !important;
    /* identical to box height */

    Link {
      padding-left: 0;
    }
    /* Deep Green */
  }

  .ant-menu {
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.313rem;
    color: $gray-600 !important;
  }
  :where(.css-yp8pcc).ant-menu-light
    .ant-menu-submenu-selected
    > .ant-menu-submenu-title,
  :where(.css-yp8pcc).ant-menu-light
    > .ant-menu
    .ant-menu-submenu-selected
    > .ant-menu-submenu-title {
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.313rem;
    color: $gray-600 !important;

    Link {
      padding-left: 0;
    }
  }

  :where(.css-dev-only-do-not-override-2q8sxy).ant-menu-light
    .ant-menu-submenu-selected
    > .ant-menu-submenu-title,
  :where(.css-dev-only-do-not-override-2q8sxy).ant-menu-light
    > .ant-menu
    .ant-menu-submenu-selected
    > .ant-menu-submenu-title {
    color: $gray-600;
    padding: 0 !important;
    background: $white !important;
  }

  :where(.css-dev-only-do-not-override-2q8sxy).ant-menu-light.ant-menu-inline
    .ant-menu-sub.ant-menu-inline,
  :where(.css-dev-only-do-not-override-2q8sxy).ant-menu-light
    > .ant-menu.ant-menu-inline
    .ant-menu-sub.ant-menu-inline {
    background: $white;
  }

  :where(.css-dev-only-do-not-override-2q8sxy).ant-menu-light:not(
      .ant-menu-horizontal
    )
    .ant-menu-submenu-title:hover,
  :where(.css-dev-only-do-not-override-2q8sxy).ant-menu-light
    > .ant-menu:not(.ant-menu-horizontal)
    .ant-menu-submenu-title {
    background-color: $white;
    padding-left: 0 !important;
    &:hover {
      background-color: $white;
      padding-left: 0 !important;
    }
  }

  .ant-menu-submenu-title {
    padding-left: 0px !important;
    display: flex;
    align-items: center;
    border-radius: unset;
  }

  .sidebarHeading {
    border-inline-end: unset !important;

    .menu-item {
      display: flex;
      align-items: center;
      padding-left: 1.813rem !important;
      height: 50px;
      background-color: $white;
      border-radius: unset;
    }

    .text.ff{
      color: red;
    }

    .subMenu {
      padding-left: 1.813rem !important;
      background-color: $white;
      border-radius: unset;
    }
    .subMenu-item {
      padding-left: 0.625rem !important;
      height: 50px;
      background-color: $white;
      border-radius: unset;
    }
  }

  nav {
    display: grid;
    gap: 24px;
    //height: 100%;
    //overflow-y: auto;

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      font-family: $font-family-sans-serif;
   

      color: var(--foundation-blue-secondary-text-200, #9D9D9D);
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
    }
  }

  @media (max-width: 991px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100vh;
    padding-inline: 0;
    box-sizing: border-box;
    overflow: hidden;
    transition: linear width 200ms;
  }
}

.sidebarMainActivated {
  position: fixed;
  top: 100px;
  right: -250px;
  width: 250px;
  height: 100%;
  background: $white;
  transition: 0.4s;
  z-index: 100;
  right: 0;
  padding-top: 0;
}

.menu-link-text{
  color: var(--foundation-blue-secondary-text-200, #9D9D9D);
font-family: 'Poppins';
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
}


:where(.css-dev-only-do-not-override-2q8sxy).ant-menu .ant-menu-item a::before {
//     border-radius: 4px;
// background: var(--foundation-yellow-primary-100, #FBE9B2);
}


.text-primary{
display: flex;
width: 213px;
padding: 10px;
justify-content: center;
align-items: center;
gap: 10px;


border-radius: 4px;
background: var(--foundation-yellow-primary-100, #FBE9B2);
}


